import Sortable from 'sortablejs';

document.addEventListener('DOMContentLoaded', () => {
  // Licenseのソートを行う
  const el = document.querySelector(".js-licenses-list");
  if(el) {
    Sortable.create(
      el,
      {
        dataIdAttr: 'data-license-id',
        onUpdate: (e) => {
          $.ajax({
            url: `/admin/licenses/${e.item.dataset.licenseId}`,
            method: 'patch',
            data: {
              license: {
                position: e.newIndex + 1,
              },
              authenticity_token: document.getElementsByName('csrf-token')[0].getAttribute('content')
            }
          });
        }
      });
  }
});
