$(() => {
  const $dropdown = $('.l-header__menu__item');
  const $dropdownMenu = $dropdown.find('.l-header__menu__item__dropdown');

  $dropdown.on('click', () => {
    if ($dropdownMenu.is(':visible')) {
      $dropdownMenu.hide();
    } else {
      $dropdownMenu.show();
    }
  });

  // Dropdown menuを閉じる処理
  // note: dropdownがclickされたときもhideされちゃうので、dropdownがclickされたときは伝搬を止める
  $dropdown.on('click', (e) => { e.stopPropagation(); });
  $(window).on('click', () => { $dropdownMenu.hide(); });
});
