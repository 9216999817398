$(() => {
  const MODAL_TRIGGER_SELECTOR = '.js-remote-modal-trigger';
  const $body = $('body');

  addEvents();

  function addEvents() {
    $(document)
      .on('ajax:before', MODAL_TRIGGER_SELECTOR, disableClick)
      .on('ajax:complete', MODAL_TRIGGER_SELECTOR, enableClick)
      .on('ajax:success', MODAL_TRIGGER_SELECTOR, ajaxSuccessCallback);
  }

  function ajaxSuccessCallback(e) {
    settingAndShowModal(e.detail[2].response);
  }

  function settingAndShowModal(modalDom) {
    $('body').find('.modal').modal('hide'); // Modalは一つまで。

    const $modal = $(modalDom);
    $body.append($modal);

    $modal.modal('show');

    $modal.on('hidden.bs.modal', () => {
      $modal.remove();
      $('.modal-backdrop').remove();
    });
  }

  function disableClick(event) {
    const $target = $(event.currentTarget);
    // submit buttonをdisableにするべき
    if ($target.get(0).tagName === 'FORM') { return; }

    $target.prop('disabled', true);
  }

  function enableClick(event) {
    const $target = $(event.currentTarget);
    if ($target.get(0).tagName === 'FORM') { return; }

    $target.prop('disabled', false);
  }
});
